enum ShortlistFilterType {
  HOST_NULL = "HOST_NULL",
  HOST_DEFAULT = "HOST_DEFAULT",
  HOST_SEARCH = "HOST_SEARCH",
  HOST_RANGE = "HOST_RANGE",
  OPTION = "OPTION",
  CHECKBOX = "CHECKBOX",
  COLOR = "COLOR",
  RANGE = "RANGE",
  ICON = "ICON",
  NULL = "NULL",
}

interface ShortlistFilterMetadata {
  readonly alias: string;
  readonly collapsed: boolean | null;
  readonly negativeEditable: boolean | null;
  readonly positiveEditable: boolean | null;
}

interface ShortlistFilter {
  readonly id: string;
  readonly type: ShortlistFilterType;
  readonly children?: ShortlistFilter[];
  readonly translationKey: string;
  readonly metadata?: ShortlistFilterMetadata;
}

const isHostFilter = (filterType: ShortlistFilterType): boolean =>
  [
    ShortlistFilterType.HOST_DEFAULT,
    ShortlistFilterType.HOST_NULL,
    ShortlistFilterType.HOST_RANGE,
    ShortlistFilterType.HOST_SEARCH,
  ].includes(filterType);

export type { ShortlistFilter as ShortlistFilterProjection };
export { isHostFilter, ShortlistFilterType };
