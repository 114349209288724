import { FC, ReactNode, useCallback } from "react";
import { Locale } from "@lookiero/i18n";
import {
  useCountPlannedBoxesByCriteria,
  useSearchPlannedBoxesByCriteria,
} from "../../../../core/infrastructure/projection/plannedBox/react/useSearchPlannedBoxesByCriteria";
import useEnvironment from "../../_behaviors/useEnvironment";
import { PlannedBox } from "../plannedBox/PlannedBox";
import VirtualizedList from "../../../../shared/ui/uiKit/layouts/virtualizedList/VirtualizedList";
import {
  PlannedBox as PlannedBoxProjection,
  PlannedBoxTag,
  PlannedBoxesAssignment,
} from "../../../../core/projection/plannedBox/plannedBox";
import Country from "../../../../core/domain/country/model/Country";
import { PlannedBoxSkeleton } from "../plannedBox/PlannedBoxSkeleton";
import { Routes } from "../../../_routing/Routing";
import "./planned-boxes.css";
import { generatePath } from "react-router-dom";
import { Segment } from "../../../../core/projection/segment/segment";

const ITEM_HEIGHT = 52;

const backgroundColorForPlannedBoxTag = {
  [PlannedBoxTag.READY]: "#ffffff",
  [PlannedBoxTag.PREVIEW_SENT]: "#b4d3e5",
  [PlannedBoxTag.PREVIEW_SUBMITTED]: "#b399fc",
  [PlannedBoxTag.COMPLETED]: "#9de0bf",
  [PlannedBoxTag.POSSIBLE_FRAUD_AND_REJECTED]: "#fcc799",
};

interface PlannedBoxUrlFunctionArgs {
  readonly locale: string;
  readonly boxNumber: string;
}
interface PlannedBoxUrlFunction {
  (args: PlannedBoxUrlFunctionArgs): string;
}
const plannedBoxUrl: PlannedBoxUrlFunction = ({ locale, boxNumber }) => {
  return window.location !== window.parent.location
    ? document.referrer.concat(`box/${boxNumber}`)
    : generatePath(Routes.BOX, { locale, box: boxNumber });
};

interface PlannedBoxesListItem {
  (index: number, plannedBox: PlannedBoxProjection | null): ReactNode;
}

interface PlannedBoxesProps {
  readonly locale: Locale;
  readonly plannedFor: Date;
  readonly country: Country[] | undefined;
  readonly psNumber: string | undefined;
  readonly boxNumber: string | undefined;
  readonly assignment: PlannedBoxesAssignment | undefined;
  readonly segment: Segment | undefined;
  readonly tag: PlannedBoxTag[] | undefined;
  readonly page: number;
  readonly onPageChanged: (page: number) => void;
}

const PlannedBoxes: FC<PlannedBoxesProps> = ({
  locale,
  plannedFor,
  country,
  psNumber,
  boxNumber,
  assignment,
  segment,
  tag,
  page,
  onPageChanged,
}) => {
  const {
    plannedBoxes: { perPage },
  } = useEnvironment();
  const itemsPerRow = useCallback(() => 1, []);
  const estimatedItemHeight = useCallback(() => ITEM_HEIGHT, []);

  const plannedBoxesCount = useCountPlannedBoxesByCriteria({
    boxNumber,
    country,
    plannedFor,
    psNumber,
    assignment,
    tag,
    segment,
  });

  const plannedBoxes = useSearchPlannedBoxesByCriteria({
    boxNumber,
    country,
    page,
    perPage,
    plannedFor,
    psNumber,
    assignment,
    tag,
    segment,
  });

  const handleOnNavigateToBox = useCallback(
    (boxNumber: string) => window.open(plannedBoxUrl({ locale, boxNumber }), "_parent"),
    [locale],
  );

  const plannedBoxItem: PlannedBoxesListItem = useCallback(
    (index, plannedBox) =>
      plannedBox ? (
        plannedBox.boxNumber ? (
          <PlannedBox
            key={plannedBox.boxNumber}
            locale={locale}
            plannedBox={plannedBox}
            onNavigateToBox={handleOnNavigateToBox}
          />
        ) : (
          <PlannedBoxSkeleton key={index} />
        )
      ) : (
        <td key={index} />
      ),
    [handleOnNavigateToBox, locale],
  );

  const listRowStyleFn = useCallback(
    (data: [number, PlannedBoxProjection][]) =>
      data[0][1].tag ? { backgroundColor: backgroundColorForPlannedBoxTag[data[0][1].tag] } : {},
    [],
  );

  return (
    <VirtualizedList
      className={{ virtualizedList: "planned-boxes", listRow: "planned-boxes__planned-box" }}
      data={plannedBoxes}
      estimatedItemHeight={estimatedItemHeight}
      itemCount={plannedBoxesCount}
      itemsPerRow={itemsPerRow}
      listRowStyleFn={listRowStyleFn}
      page={page}
      perPage={perPage}
      tagName={{
        innerElementTagName: "tbody",
        listRow: "tr",
        outerElementTagName: "table",
      }}
      onPageChanged={onPageChanged}
    >
      {plannedBoxItem}
    </VirtualizedList>
  );
};

export { PlannedBoxes };
