import { HttpGetFunction } from "../../delivery/http/httpClient";
import { camelcaseKeys } from "../../../../shared/delivery/infrastructure/camelcaseKeys";
import { Environment } from "../../../projection/environment/model/Environment";

interface HttpEnvironmentFetcherFunctionArgs {
  readonly httpGet: HttpGetFunction;
}

interface HttpEnvironmentFetcherFunction {
  (args: HttpEnvironmentFetcherFunctionArgs): Promise<Environment | null>;
}

const httpEnvironmentFetcher: HttpEnvironmentFetcherFunction = ({ httpGet }) =>
  httpGet<Environment, Environment | null>({
    endpoint: `/config.json?${Date.now()}`,
    result: {
      error: null,
      success: (response) => camelcaseKeys(response),
    },
  });

export { httpEnvironmentFetcher };
