import { createRoot } from "react-dom/client";
import Cookies from "js-cookie";
import * as serviceWorker from "./serviceWorker";
import { bootstrap as messagingBootstrap } from "./core/container/bootstrap";
import { bootstrap } from "./core/container/bootstrap.container";
import { Root } from "./ui/views/root/Root";
import FeatureToggles from "./ui/_featureToggle/FeatureToggles";
import { Routes } from "./ui/_routing/Routing";
import { httpEnvironmentFetcher } from "./core/infrastructure/projection/environment/httpEnvironmentFetcher";
import { fetchHttpGet } from "./core/infrastructure/delivery/http/fetchHttpClient";
import "./shared/ui/uiKit/_tokens/index";
import "./ui/index.css";
import "./ui/componentLibrary/_uiKitTheme/index";

(async () => {
  const authToken = Cookies.get("authtoken");

  const httpGet = fetchHttpGet({ apiUrl: () => "", getAuthToken: async () => authToken || "" });
  const environment = await httpEnvironmentFetcher({ httpGet });

  if (!environment) {
    return null;
  }

  const { Component: MessagingRoot } = messagingBootstrap({
    apiUrl: environment.labsBackUrl,
    legacyApiUrl: environment.labsBackLegacyUrl,
    authToken: authToken as string,
    environment,
  });
  const { I18nRootComponent, logger, tracker, firewalls, featureToggles } = await bootstrap({ environment });

  const firewallConfig = {
    [Routes.PLANNING]: firewalls.planning,
    [Routes.REAL_TIME_BOX_PRODUCTION]: firewalls.realTimeBoxProduction,
    [Routes.CAPACITY]: firewalls.capacity,
  };

  const featureToggleConfig = {
    [FeatureToggles.REAL_TIME_BOX_PRODUCTION]: featureToggles.realTimeBoxProduction,
    [FeatureToggles.STOCK_SURVEY_TYPEFORM]: featureToggles.stockSurveyTypeform,
    [FeatureToggles.AUTOMATIC_SELECTION_TYPEFORM]: featureToggles.automaticSelectionTypeform,
    [FeatureToggles.CUSTOMER_INSIGHTS]: featureToggles.customerInsights,
  };

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById("root")!);
  root.render(
    <MessagingRoot>
      <Root
        I18nRootComponent={I18nRootComponent}
        environment={environment}
        featureToggleConfig={featureToggleConfig}
        firewallConfig={firewallConfig}
        logger={logger}
        tracker={tracker}
      />
    </MessagingRoot>,
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
