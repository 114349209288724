import { translationExternalEndpoint } from "../infrastructure/projection/translations/model/translationEndpoint";
import { I18n, i18n } from "@lookiero/i18n-react";
import { fetchTranslations } from "../infrastructure/projection/translations/model/fetchTranslations";
import AuthCookieBasedFeatureToggle from "../../shared/featureToggle/AuthCookieBasedFeatureToggle";
import FeatureToggleRouteFirewall from "../../ui/_firewall/FeatureToggleRouteFirewall";
import { AsyncGtmTracker } from "../../shared/tracking/infrastructure/AsyncGtmTracker";
import { Tracker } from "../../shared/tracking/Tracker";
import { Logger } from "../../shared/logging/Logger";
import SentryLogger from "../../shared/logging/SentryLogger";
import packageInfo from "../../../package.json";
import { read as readCookieStorage } from "../infrastructure/persistence/cookieStorage";
import { Environment } from "../projection/environment/model/Environment";
import { CustomerWindowsMessenger } from "../infrastructure/integration/customer/CustomerWindowsMessenger";
import { PostMeWindowsMessengerChild } from "@lookiero/windows-messenger.js";

interface FeatureToggles {
  readonly realTimeBoxProduction: AuthCookieBasedFeatureToggle;
  readonly planning: AuthCookieBasedFeatureToggle;
  readonly stockSurveyTypeform: AuthCookieBasedFeatureToggle;
  readonly automaticSelectionTypeform: AuthCookieBasedFeatureToggle;
  readonly capacity: AuthCookieBasedFeatureToggle;
  readonly customerInsights: AuthCookieBasedFeatureToggle;
}

interface Firewalls {
  readonly realTimeBoxProduction: FeatureToggleRouteFirewall;
  readonly planning: FeatureToggleRouteFirewall;
  readonly capacity: FeatureToggleRouteFirewall;
}

interface BootstrapFunctionArgs {
  readonly environment: Environment;
}

interface BootstrapFunctionReturn {
  readonly I18nRootComponent: I18n;
  readonly featureToggles: FeatureToggles;
  readonly firewalls: Firewalls;
  readonly tracker: Tracker;
  readonly logger: Logger;
}

interface BootstrapFunction {
  (args: BootstrapFunctionArgs): Promise<BootstrapFunctionReturn>;
}

const bootstrap: BootstrapFunction = async ({ environment }) => {
  const {
    internationalization: { defaultLocale, externalEndpoint },
    toggleFeatures: {
      isRealTimeBoxProductionEnabled,
      isPlanningEnabled,
      isStockSurveyTypeformEnabled,
      isAutomaticSelectionTypeformEnabled,
      isCapacityEnabled,
      isCustomerInsightsEnabled,
    },
    tracking,
    logging,
  } = environment;

  /* I18n */
  const I18nRootComponent = i18n({
    fetchTranslation: fetchTranslations({
      translations: [
        translationExternalEndpoint({
          translationsUrl: externalEndpoint,
          projects: [["labs"], ["inventory-catalog"], ["checkout"], ["style-profile"]],
          defaultLocale,
        }),
      ],
    }),
    contextId: "LabsI18n",
  });

  /* Tracker */
  const { project, gtmId } = tracking;
  const tracker = await AsyncGtmTracker.init({ project, gtmId });

  /* Logger */
  const { sentryProject, sentryPublicKey } = logging;
  const logger = new SentryLogger({
    environment: process.env.NODE_ENV === "production" ? "web-PROD" : "web-DEV",
    release: packageInfo.version,
    project: sentryProject,
    publicKey: sentryPublicKey,
  });

  /* FeatureToggles */
  const featureToggles: FeatureToggles = {
    realTimeBoxProduction: new AuthCookieBasedFeatureToggle(readCookieStorage, isRealTimeBoxProductionEnabled),
    planning: new AuthCookieBasedFeatureToggle(readCookieStorage, isPlanningEnabled),
    stockSurveyTypeform: new AuthCookieBasedFeatureToggle(readCookieStorage, isStockSurveyTypeformEnabled),
    automaticSelectionTypeform: new AuthCookieBasedFeatureToggle(
      readCookieStorage,
      isAutomaticSelectionTypeformEnabled,
    ),
    capacity: new AuthCookieBasedFeatureToggle(readCookieStorage, isCapacityEnabled),
    customerInsights: new AuthCookieBasedFeatureToggle(readCookieStorage, isCustomerInsightsEnabled),
  };

  /* Firewalls */
  const fallbackComponent = () => null;
  const firewalls: Firewalls = {
    realTimeBoxProduction: new FeatureToggleRouteFirewall([featureToggles.realTimeBoxProduction], fallbackComponent),
    planning: new FeatureToggleRouteFirewall([featureToggles.planning], fallbackComponent),
    capacity: new FeatureToggleRouteFirewall([featureToggles.capacity], fallbackComponent),
  };

  /* WindowsMessenger */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  new CustomerWindowsMessenger(new PostMeWindowsMessengerChild());

  return {
    tracker,
    logger,
    I18nRootComponent,
    featureToggles,
    firewalls,
  };
};

export { bootstrap };
